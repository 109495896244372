import * as React from "react";
import { Container, Row } from "react-bootstrap";
import { GaleryContentProps } from "../pages";

import PhotosCarousel from "../components/photos-carousel";

const AtrractionsSection: React.FC<{ cms: GaleryContentProps }> = ({ cms }) => {
  const onSwiperCreated = (swiper) => {};
  return (
    <section
      id="attractionsSection"
      className="attractions-section py-5 pe-xl-5"
    >
      <Container fluid className="attractions-section__banner h-100 ps-xl-0">
        <h2 className="text-center mb-5">{cms.title}</h2>
        <PhotosCarousel
          photos={cms.gallery}
          swipperCreated={onSwiperCreated}
          dir={"ltr"}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            900: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        ></PhotosCarousel>
      </Container>
    </section>
  );
};

export default AtrractionsSection;
