import React from "react";
import { Helmet } from "react-helmet";

export function Seo({
  title,
  longTitle,
  author,
  description,
  lang = "pl-PL",
  meta = [],
  image,
}: SEOProps) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={longTitle ? `%s | ${longTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: longTitle,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image.url,
        },
        {
          property: `og:image:width`,
          content: image.width.toString(),
        },
        {
          property: `og:image:height`,
          content: image.height.toString(),
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: longTitle,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:image`,
          content: image.url,
        },
        {
          property: `twitter:image:width`,
          content: image.width.toString(),
        },
        {
          property: `twitter:image:height`,
          content: image.height.toString(),
        },
      ].concat(meta)}
    />
  );
}

// Types
type SEOProps = {
  description?: string;
  lang?: string;
  meta?: Meta;
  title: string;
  longTitle: string;
  author: string;
  image: {
    url: string;
    width: number;
    height: number;
  };
};

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>;

type PropertyMetaObj = {
  property: string;
  content: string;
};

type NameMetaObj = {
  name: string;
  content: string;
};

type QueryTypes = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
    };
  };
};
