import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CircularButton from "../components/circular-button";
import { ImageContentProps } from "../pages";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const AboutInvestSection: React.FC<{ cms: ImageContentProps }> = ({ cms }) => (
  <section
    id="aboutInvestSection"
    className="about-invest-section d-flex flex-column py-5"
  >
    <Container className="about-invest-section__banner flex-fill d-flex flex-column justify-content-center align-items-center">
      <Row>
        <Col
          xs={12}
          lg={6}
          className="about-invest-section__banner_left d-flex flex-column justify-content-center align-items-center"
        >
          <h2>{cms.title}</h2>
          <Markdown rehypePlugins={[rehypeRaw]}>{cms.content}</Markdown>

          <CircularButton
            id="selectLocalizationLinkButton"
            text="Znajdź swoja działkę"
            link="#localizationSection"
            className="white"
          ></CircularButton>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="about-invest-section__banner_right d-flex flex-column justify-content-center align-items-center"
        >
          <img
            src={cms.image.localFile.publicURL}
            alt="About invest banner"
          ></img>
        </Col>
      </Row>
    </Container>
  </section>
);

export default AboutInvestSection;
