import * as React from "react";
import Layout from "../components/layout";
import AboutInvestSection from "../sections/about-invest-section";
import HomeSection from "../sections/home-section";
import NeighborhoodSection from "../sections/neighborhood-section";
import { graphql } from "gatsby";
import LocalizationSection from "../sections/localization-section";
import AtrractionsSection from "../sections/attractions-section";
import MapSection from "../sections/map-section";
import ContactSection from "../sections/contact-section";
import { Seo } from "../components/seo";
import InvestmentAdvantagesSection from "../sections/investment-advanteges-section";

export interface IndexPageProps {
  data: {
    strapiSlowinskaOaza: {
      home_banner: HomeProps;
      about_invest_banner: ImageContentProps;
      investment_advantages_banner: {
        title: string | null;
        image: ImageProps;
      }[];
      neighborhood_banner: GaleryContentProps;
      localization_banner: ImageContentProps;
      attractions_banner: GaleryContentProps;
      contact_banner: ContactProps;
      plots: any[];
      seo: SeoProps;
    };
  };
}

export interface GaleryContentProps {
  title: string;
  content: string;
  gallery: {
    title: string | null;
    image: ImageProps;
  }[];
}

export interface HomeProps {
  title: string;
  content: string;
}

export interface ContactProps {
  google_map_frame: string;
  email: string;
  phone: string;
  copyrights: string;
}

export interface ImageContentProps {
  title: string;
  content: string;
  image: ImageProps;
}

export interface SeoProps {
  author: string;
  title: string;
  long_title: string;
  description: string;
  keyword: {
    tag: string;
  }[];
  image: {
    url: string;
    width: number;
    height: number;
  };
}

export interface ImageProps {
  localFile: {
    publicURL: string;
  };
}

const IndexPage: React.FC<IndexPageProps> = (cms) => (
  <Layout>
    <Seo
      author={cms.data.strapiSlowinskaOaza.seo.author}
      title={cms.data.strapiSlowinskaOaza.seo.title}
      longTitle={cms.data.strapiSlowinskaOaza.seo.long_title}
      description={cms.data.strapiSlowinskaOaza.seo.description}
      image={cms.data.strapiSlowinskaOaza.seo.image}
    ></Seo>
    <HomeSection cms={cms.data.strapiSlowinskaOaza.home_banner}></HomeSection>
    <AboutInvestSection
      cms={cms.data.strapiSlowinskaOaza.about_invest_banner}
    ></AboutInvestSection>
    <InvestmentAdvantagesSection
      cms={cms.data.strapiSlowinskaOaza.investment_advantages_banner}
    ></InvestmentAdvantagesSection>
    <NeighborhoodSection
      cms={cms.data.strapiSlowinskaOaza.neighborhood_banner}
    ></NeighborhoodSection>
    <LocalizationSection
      cms={cms.data.strapiSlowinskaOaza.localization_banner}
      plots={cms.data.strapiSlowinskaOaza.plots}
    ></LocalizationSection>
    <AtrractionsSection
      cms={cms.data.strapiSlowinskaOaza.attractions_banner}
    ></AtrractionsSection>
    <MapSection cms={cms.data.strapiSlowinskaOaza.contact_banner}></MapSection>
    <ContactSection
      cms={cms.data.strapiSlowinskaOaza.contact_banner}
    ></ContactSection>
  </Layout>
);

export const query = graphql`
  query {
    strapiSlowinskaOaza {
      home_banner {
        title
        content
      }
      about_invest_banner {
        title
        content
        image {
          localFile {
            publicURL
          }
        }
      }
      investment_advantages_banner {
        image {
          localFile {
            publicURL
          }
        }
        title
      }
      neighborhood_banner {
        title
        content
        gallery {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      localization_banner {
        title
        content
        image {
          localFile {
            publicURL
          }
        }
      }
      attractions_banner {
        title
        gallery {
          title
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      contact_banner {
        google_map_frame
        email
        phone
        copyrights
      }
      plots {
        id
        title
        description
        disabled
        price
        coords
        shape
      }
      seo {
        author
        title
        long_title
        description
        keyword {
          tag
        }
        image {
          url
          width
          height
        }
      }
    }
  }
`;

export default IndexPage;
