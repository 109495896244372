import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CircularButton from "../components/circular-button";
import { GaleryContentProps, ImageContentProps, ImageProps } from "../pages";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const InvestmentAdvantagesSection: React.FC<{
  cms: {
    title: string | null;
    image: ImageProps;
  }[];
}> = ({ cms }) => (
  <section
    id="investmentAdvantegesSection"
    className="investment-advanteges-section d-flex flex-column pb-5"
  >
    <Container className="investment-advanteges-section__banner flex-fill d-flex flex-column justify-content-center align-items-center">
      <Row className="investment-advanteges-section__banner_icons w-100 mt-5">
        {cms.map((element, index) => {
          return (
            <Col
              xs={12}
              lg={3}
              className="investment-advanteges-section__banner_icon_container text-center border-left border-right mt-5 mt-xl-0"
            >
              <img
                src={element.image.localFile.publicURL}
                alt={element.title}
              ></img>
              <p>{element.title}</p>
            </Col>
          );
        })}
      </Row>
    </Container>
  </section>
);

export default InvestmentAdvantagesSection;
