import * as React from "react";
import { Container, Row } from "react-bootstrap";
import { ContactProps } from "../pages";
import logo from "../assets/contact-logo.png";

const ContactSection: React.FC<{ cms: ContactProps }> = ({ cms }) => {
  return (
    <section
      id="contactSection"
      className="contact-section d-flex flex-column pt-5"
    >
      <Container className="contact-section__banner flex-fill d-flex flex-column justify-content-center align-items-center">
        <img className="mb-2" src={logo}></img>
        <h2 className="contact-section__header text-center mt-5">
          ZNAJDŹ SWOJĄ DZIAŁKĘ
        </h2>
        <h3 className="contact-section__phone mb-5">{cms.phone}</h3>
        <a className="nav-btn" href={`mailto:${cms.email}`}>
          Wyślij wiadomość
        </a>
        <p className="contact-section__banner_email mt-2">{cms.email}</p>
      </Container>
      <p className="contact-section__banner_copyrights ms-auto pe-5 mt-5">
        {cms.copyrights}
      </p>
    </section>
  );
};

export default ContactSection;
