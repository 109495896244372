import * as React from "react";
import { Container } from "react-bootstrap";
import CircularButton from "../components/circular-button";
import { HomeProps } from "../pages";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const HomeSection: React.FC<{ cms: HomeProps }> = ({ cms }) => (
  <section className="home-section d-flex flex-column">
    <Container className="flex-fill d-flex flex-column justify-content-center align-items-center">
      <div className="home-section__banner">
        <Markdown rehypePlugins={[rehypeRaw]}>{cms.title}</Markdown>
        <Markdown rehypePlugins={[rehypeRaw]}>{cms.content}</Markdown>
        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center mt-5 mt-md-0">
          <a
            id="selectLocalizationLinkButton"
            className="nav-btn mx-3"
            href="#localizationSection"
          >
            Znajdź swoja działkę
          </a>
          <CircularButton
            id="contactLinkButton"
            text="Skontaktuj się z nami"
            className="white mx-3"
            link="#contactSection"
          ></CircularButton>
        </div>
      </div>
    </Container>
  </section>
);

export default HomeSection;
