import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CircularButton from "../components/circular-button";
import { GaleryContentProps } from "../pages";

import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PhotosCarousel from "../components/photos-carousel";

const NeighborhoodSection: React.FC<{ cms: GaleryContentProps }> = ({
  cms,
}) => {
  const onSwiperCreated = (swiper) => {};
  return (
    <section
      id="neighborhoodSection"
      className="neighborhood-section py-5 pe-xl-5"
    >
      <Container fluid className="neighborhood-section__banner h-100 ps-xl-0">
        <Row className="h-100">
          <Col xs={12} xl={7} className="neighborhood-section__banner_left">
            <PhotosCarousel
              photos={cms.gallery}
              swipperCreated={onSwiperCreated}
              dir={"rtl"}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
              }}
            ></PhotosCarousel>
          </Col>
          <Col
            xs={12}
            xl={5}
            className="neighborhood-section__banner_right d-flex flex-column justify-content-center align-items-center mt-5 mt-xl-0"
          >
            <h2>{cms.title}</h2>
            <Markdown rehypePlugins={[rehypeRaw]}>{cms.content}</Markdown>
            <CircularButton
              id="selectLocalizationLinkButton"
              text="Znajdź swoja działkę"
              link="#localizationSection"
              className="secondary"
            ></CircularButton>
          </Col>
        </Row>
        <div className="neighborhood-section__wrapper_left d-none d-lg-block"></div>
        <div className="neighborhood-section__wrapper_right d-none d-lg-block"></div>
      </Container>
    </section>
  );
};

export default NeighborhoodSection;
