import * as React from "react";
import { Container, Row } from "react-bootstrap";
import { ContactProps } from "../pages";

const MapSection: React.FC<{ cms: ContactProps }> = ({ cms }) => {
  return (
    <section id="mapSection" className="map-section d-flex flex-column py-5">
      <Container className="map-section__banner flex-fill d-flex flex-column justify-content-center align-items-center">
        <h2>Mapa okolicy</h2>
        <iframe
          src={cms.google_map_frame}
          title="Warka Park on Google Maps"
          width="100%"
          height="100%"
          frameBorder="0"
          aria-hidden="false"
        ></iframe>
      </Container>
      <div className="map-section__wrapper"></div>
    </section>
  );
};

export default MapSection;
