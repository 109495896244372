import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavigationBar from "./navigation-bar";

const MainLayout: React.FC = ({ children }) => (
  <Container fluid data-bs-spy="scroll" data-bs-target="#site-navbar">
    <Row>
      <NavigationBar></NavigationBar>
      <main className="px-0 main">{children}</main>
    </Row>
  </Container>
);

export default MainLayout;
