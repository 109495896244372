import React, { ReactElement } from "react";
import { ImageProps } from "../pages";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";

import SinglePhoto from "./single-photo";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import { AutoplayOptions } from "swiper/types/components/autoplay";

type SourceProps = {
  swipperCreated: (swiper: any) => void;
  photos: {
    title: string | null;
    image: ImageProps;
  }[];
  breakpoints: any;
  dir: string;
  autoplay: AutoplayOptions | boolean;
};

SwiperCore.use([Autoplay]);

const PhotosCarousel = ({
  swipperCreated,
  photos,
  breakpoints,
  dir,
  autoplay,
}: SourceProps): ReactElement => {
  return (
    <React.Fragment>
      <Swiper
        className="photos-carousel h-100"
        loop={false}
        onSwiper={(swiper) => swipperCreated(swiper)}
        dir={dir}
        breakpoints={breakpoints}
        navigation={true}
        autoplay={autoplay}
      >
        {photos.map((element, index) => {
          return (
            <SwiperSlide key={index} className="photos-carousel__slide">
              <SinglePhoto photo={element}></SinglePhoto>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </React.Fragment>
  );
};

export default PhotosCarousel;
