import * as React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { TextRight } from "react-bootstrap-icons";
import logo from "../assets/logo.svg";

const NavigationBar: React.FC = () => {
  const [expanded, setExpanded] = React.useState(false);

  const navToggle = () => {
    setExpanded(expanded ? false : true);
  };

  const closeNav = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="xl" id="site-navbar" expanded={expanded}>
      <div className="container-xl">
        <Image src={logo} width="120px" className="d-block d-xl-none"></Image>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="ms-auto shadow-none"
          onClick={navToggle}
        >
          <div className="nav-toggle">
            <TextRight className="nav-toggle__icon" size={48}></TextRight>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
            <Nav.Link href="#aboutInvestSection" onClick={closeNav}>
              O inwestycji
            </Nav.Link>
            <Nav.Link href="#neighborhoodSection" onClick={closeNav}>
              Okolica
            </Nav.Link>
            <Nav.Link href="#localizationSection" onClick={closeNav}>
              Lokalizacja
            </Nav.Link>
            <Image
              src={logo}
              width="120px"
              className="d-xl-block d-none"
            ></Image>
            <Nav.Link href="#attractionsSection" onClick={closeNav}>
              Atrakcje
            </Nav.Link>
            <Nav.Link href="#mapSection" onClick={closeNav}>
              Mapa okolicy
            </Nav.Link>
            <Nav.Link href="#contactSection" onClick={closeNav}>
              Kontakt
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
export default NavigationBar;
