import React, { ReactElement } from "react";

const defaultProps = {
  direction: `right`,
};

type SourceProps = {
  direction: string;
  link: string;
  className: string;
  text: string;
  id: string;
} & typeof defaultProps;

const CircularButton = ({
  direction,
  text,
  link,
  className,
  id,
}: SourceProps): ReactElement => {
  return (
    <React.Fragment>
      <a id={id} className={"circular-nav-btn " + className} href={link}>
        {text}
        <svg height="100px" width="100px">
          <g>
            <line x1="30" x2="60" y1="50" y2="50"></line>
            <polyline points=" 55,45 60,50 55,55"></polyline>
            <circle cx="50" cy="50" r="30.5"></circle>
          </g>
        </svg>
      </a>
    </React.Fragment>
  );
};

export default CircularButton;

CircularButton.defaultProps = defaultProps;
