import * as React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import CircularButton from "../components/circular-button";
import { ImageContentProps } from "../pages";
import ImageMapper from "react-img-mapper";

import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useState } from "react";

function useWindowSize() {
  const [size, setSize] = useState([320, 530]);
  React.useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 420) {
        setSize([320, 420]);
      } else if (window.innerWidth < 650) {
        setSize([400, 520]);
      } else if (window.innerWidth > 640) {
        setSize([640, 830]);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const LocalizationSection: React.FC<{ cms: ImageContentProps; plots: any[] }> =
  ({ cms, plots }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedPlot, setSelectedPlot] = useState(false);
    const [width, height] = useWindowSize();
    const onMapClick = (plot) => {
      setSelectedPlot(plot);
      setShowModal(true);

      window.dataLayer.push({
        event: "plot_selected",
        plot_name: plot.title,
      });
    };

    const handleClick = () => {
      setShowModal(!showModal);
    };

    return (
      <>
        <section
          id="localizationSection"
          className="localization-section d-flex flex-column py-5"
        >
          <Container className="localization-section__banner flex-fill d-flex flex-column justify-content-center align-items-center">
            <Row>
              <Col
                xs={12}
                lg={5}
                className="localization-section__banner_left d-flex flex-column justify-content-center"
              >
                <h2>{cms.title}</h2>
                <Markdown rehypePlugins={[rehypeRaw]}>{cms.content}</Markdown>
                <CircularButton
                  id="contactLinkButton"
                  text="Skontaktuj się"
                  link="#contactSection"
                  className="secondary"
                ></CircularButton>
              </Col>
              <Col
                id="mapContainer"
                xs={12}
                lg={7}
                className="localization-section__banner_right d-flex flex-column justify-content-center align-items-center"
              >
                <ImageMapper
                  src={cms.image.localFile.publicURL}
                  map={{
                    name: "my-map",
                    areas: plots,
                  }}
                  imgWidth={827}
                  width={width}
                  height={height}
                  key={width}
                  fillColor={"#3d8b8350"}
                  strokeColor={"black"}
                  onClick={onMapClick}
                />
                <p className="mt-2">
                  <b>
                    *Dostępne wkrótce, działki w trakcie podziału, aby uzyskać
                    więcej informacji prosimy o kontakt z naszym działem obsługi
                    klienta.
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <Modal
          size={"lg"}
          show={showModal}
          onHide={handleClick}
          centered
          className="plot-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>{selectedPlot.title}</h3>
            <p className="mt-5">
              Cena: <b>{selectedPlot.price}zł</b>
            </p>
            <p>{selectedPlot.description}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  };

export default LocalizationSection;
