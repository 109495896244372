import React from "react";
import { ImageProps } from "../pages";

const SinglePhoto: React.FC<{
  photo: {
    title: string | null;
    image: ImageProps;
  };
}> = ({ photo }) => {
  return (
    <>
      <div className="single-photo d-flex flex-column justify-content-center align-items-center h-100 m-0">
        <img
          src={photo.image.localFile.publicURL}
          alt="Image with side view"
        ></img>
        <p>{photo.title}</p>
      </div>
    </>
  );
};
export default SinglePhoto;
